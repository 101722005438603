import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { ModelViewer } from './components/ModelViewer';
import WebcamComponent from './components/WebcamComponent';
import { AppBar, Button, Container, Stack, TextField, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Logo from './components/Logo';
import CropperComponent from './components/CropperComponent';
import CanvasComponent from './components/CanvasComponent';
import axios from 'axios';
import ConversionDialog, { ConversionStage } from './components/ConversionDialog';
import AISelector, { AIDescription } from './components/AISelector';
import CropIcon from '@mui/icons-material/Crop';
import DrawIcon from '@mui/icons-material/Draw';
import SendIcon from '@mui/icons-material/Send';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


/**
 * Helper to convert image string to blob.
 * @param dataURI Image string.
 * @returns Image Blob
 */
function dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
};

async function uploadImage(image: string, model: string | null) {
    // image expected to be sent as FormData
    const formData = new FormData();
    formData.append('image', dataURItoBlob(image), 'croppedImage.jpeg');
    // specify which model to use
    if (model) {
        formData.append("model", model);
    }

    // Sende FormData an den Server und erhalte die Model ID zurück
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/submit`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
}

async function checkStatus(modelId: string) {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE}/status/${modelId}`);

    if (response.status !== 200) {
        return false;
    }

    return response.data.ready;
}


export default function App() {
    const [modelId, setModelId] = useState<string | null>(null);
    const [picture, setPicture] = useState<string | null>(null);
    const [stage, setStage] = useState<ConversionStage>(ConversionStage.IDLE);
    const [selectedAiModel, setSelectedAiModel] = useState<string | null>(null);
    const [aiModels, setAiModels] = useState<AIDescription[]>([]);
    const [defaultAiModel, setDefaultAiModel] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [useCropper, setUseCropper] = useState<boolean>(true); // Zustand für Cropper/Canvas wechseln
    const [canvasResult, setCanvasResult] = useState<string | null>(null);


    // check url parameters for debug mode
    const params = new URLSearchParams(window.location.search);
    const debug = params.get("debug") != null;

    const dbgIDRef = useRef<HTMLInputElement>();

    /**
     * Function to reset the Camera and Model view state.
     */
    function reset() {
        setPicture(null);
        setCanvasResult(null);
        setModelId(null);
    }

    function ResetButton() {
        return (
            <Button variant="contained"
                onClick={reset}
                startIcon={<DeleteForeverIcon />}
                color="error"
            >
                Reset
            </Button>
        );
    }

    function handleModelLoaded() {
        // allow dialog to be closed when 3D model has loaded in browser
        if (stage === ConversionStage.LOAD_OBJ) {
            setStage(ConversionStage.DONE);
        }
    }

    /**
     * Function handling the Image upload and status indication.
    */
    async function send() {
       // async delay function
       function delay(ms: number) {
           return new Promise(resolve => setTimeout(resolve, ms));
       };

       // clear error
       setError(null);

       // start wait dialog
       setStage(ConversionStage.UPLOAD);

        // send image to server
        try {
            const resp = await uploadImage(picture!, selectedAiModel);

            const id = resp.data.id;
            // check status
            while (!(await checkStatus(id))) {
                await delay(2000);
            }

            // model done, wait for it to be loaded in model viewer
            setStage(ConversionStage.LOAD_OBJ);
            setModelId(id);
        } catch (error) {
            setError("Failed to upload image");
            setStage(ConversionStage.DONE);
        }
    }

    /**
     * Function to load available and default Image-to-3D AI models.
     */
    async function loadAvailableModels() {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_BASE}/models`);

            // update model list and default model state
            setAiModels(resp.data["models"]);
            setDefaultAiModel(resp.data["default"]);
            setSelectedAiModel(resp.data["default"]);
        } catch (error) {
            // todo: error handling?
        }
    }

    // load available models only once
    useEffect(() => {loadAvailableModels()}, []);

    function SendButton() {
        return (
            <Button variant="contained"
            disabled={!picture}
            onClick={send}
            startIcon={<SendIcon />}
            color="success"
            >
                Upload Image
            </Button>
        );
    }

    interface EditorButtonProps {
        switchTo: "crop" | "draw"
    };

    function SwitchEditorButton({ switchTo }: EditorButtonProps) {
        function handleClick() {
            setUseCropper(switchTo === "crop")
            // update picture if something is in canvas variable
            // NOTE: all this is probably not the best solution, but it avoids having
            //       an additional button to "apply" the drawn mask in the CanvasComponent
            if (canvasResult) {
                setPicture(canvasResult);
                setCanvasResult(null);
            }
        }
        return (
            <Button variant="contained"
            disabled={!picture}
            onClick={handleClick}
            startIcon={
                switchTo === "crop" ?
                    <CropIcon />
                    :
                    <DrawIcon />
            }
            >
                { switchTo } Tool
            </Button>
        );
    }

    return (
        <React.Fragment>
            {/* Top bar */}
            <AppBar position='static' sx={{ mb: 2 }}>
                <Toolbar>
                    <Logo />
                </Toolbar>
            </AppBar>
            {/* horizontal scroll fix: https://github.com/mui/material-ui/issues/7466#issuecomment-641722893 */}
            <Container maxWidth={false}>
                {/* Webcam and 3D viewer grid */}
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        {/* Switch between Webcam and picture editor */}
                        <Typography variant="overline">
                            Image Input
                        </Typography>
                        {
                            picture ? (
                                // switch between cropper and canvas tools
                                useCropper ?
                                    <CropperComponent
                                        imageSrc={picture}
                                        onCrop={setPicture}
                                        switchButton={<SwitchEditorButton switchTo="draw" />}
                                    >
                                        <SendButton />
                                        <ResetButton />
                                    </CropperComponent>
                                    :
                                    <CanvasComponent
                                        imageSrc={picture}
                                        // onDraw gets called evey time a stroke is added/removed
                                        // this will not immediately be save to picture to keep
                                        // the undo/redo feature working
                                        onDraw={setCanvasResult}
                                        switchButton={<SwitchEditorButton switchTo="crop" />}
                                    >
                                        <SendButton />
                                        <ResetButton />
                                    </CanvasComponent>
                            )
                            :
                                // set onCapture callback to populate current picture state var
                                <WebcamComponent onCapture={setPicture}>
                                    {/* <ResetButton /> */}
                                </WebcamComponent>
                        }
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography variant="overline">
                            3D Model Output
                        </Typography>
                        <ModelViewer modelId={modelId} onLoaded={handleModelLoaded}>
                            <AISelector models={aiModels} defaultModel={defaultAiModel} onChange={setSelectedAiModel}/>
                            {
                                // add debug input to load model by id
                                debug &&
                                <React.Fragment>
                                    <Stack direction="row">
                                        <TextField variant="outlined" label="Model ID" size="small" inputRef={dbgIDRef}/>
                                        <Button variant="contained"
                                                onClick={() => setModelId(dbgIDRef.current ? dbgIDRef.current.value : null)}
                                        >
                                            Load
                                        </Button>
                                    </Stack>
                                    <Button variant='contained' onClick={() => setStage(ConversionStage.UPLOAD)}>Open Dialog</Button>
                                </React.Fragment>
                            }
                        </ModelViewer>
                    </Grid>
                </Grid>
            </Container>
            {/* Progress Dialog */}
            <ConversionDialog stage={stage} onClose={() => setStage(ConversionStage.IDLE)} error={error}/>
        </React.Fragment>
    );

}
