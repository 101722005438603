import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";


export interface AIDescription {
    name: string,
    displayName: string,
    url: string
}

export interface AISelectorProps {
    models: AIDescription[],
    defaultModel: string | null,
    onChange?: (value: string) => void;
}

/**
 * Select Component that allows to choose the Image-to-3D AI model used to generate the 3D model.
 */
export default function AISelector({ models, defaultModel, onChange }: AISelectorProps) {
    const [model, setModel] = useState<string | null>(defaultModel);

    // set current selected model to default, if model is not set yet, but default got set
    if (!model && defaultModel) {
        setModel(defaultModel);
    }

    function handleChange(event: SelectChangeEvent) {
        setModel(event.target.value);
        if (onChange) onChange(event.target.value);
    }

    return (
        <FormControl
            variant="filled"
            sx={{ minWidth: 120 }}
            size="small"
            disabled={models === undefined || models.length === 0}
        >
            <InputLabel id="select-ai-model">AI Model</InputLabel>
            <Select id="select-ai-model" label="model" value={model ?? ""} onChange={handleChange}>
                {
                    // fill selector with all items in "models" array
                    models.map(
                        (desc) => (
                            <MenuItem value={desc.name} key={desc.name}>
                                {
                                    desc.displayName
                                }
                            </MenuItem>
                        )
                    )
                }
            </Select>
        </FormControl>
    );
}