import { Button, Stack } from '@mui/material';
import { useRef, PropsWithChildren, useState } from 'react';
import Webcam from 'react-webcam';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export interface WebcamProps extends PropsWithChildren {
    onCapture?: (image: string | null) => void
}

/**
 * Webcam component.
 *
 * @param onCapture Callback function called when 'take picture' button is pressed.
 * @returns Webcam Component
 */
const WebcamComponent = ({ onCapture, children }: WebcamProps) => {
    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const webcamRef = useRef<Webcam>(null!);
    const [facingMode, setFacingMode] = useState<string>(FACING_MODE_USER);

    function handleSwitchButton() {
        if (facingMode === FACING_MODE_USER) {
            setFacingMode(FACING_MODE_ENVIRONMENT);
        }
        else {
            setFacingMode(FACING_MODE_USER);
        }
    }

    function takePicture() {
        const pic = webcamRef.current.getScreenshot();

        if (onCapture) {
            onCapture(pic);
        }
    }

    return (
        <Stack spacing={1}>
            <Webcam
                disablePictureInPicture
                audio={false}
                height="100%"
                ref={webcamRef}
                screenshotFormat="image/png"
                width="100%"
                style={{ minHeight: 512 }}
                videoConstraints={{ facingMode: facingMode }}
            />
            <Stack direction="row" spacing={1}>
                <Button variant="contained" onClick={takePicture} startIcon={<CameraAltIcon />}>Take Picture</Button>
                <Button variant='contained' onClick={handleSwitchButton}>Change Camera</Button>
                { children }
            </Stack>
        </Stack>
    );
};

export default WebcamComponent;