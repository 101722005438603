import React from "react";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Typography } from "@mui/material";

/**
 *
 * @returns Logo component used for the AppBar.
 */
export default function Logo() {
    return (
        <React.Fragment>
            <ViewInArIcon sx={{ display: { md: 'flex' }, mr: 1 }} />
            <Typography
                variant="h6"
                noWrap
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                PHOTO-2-3D
            </Typography>
            <Typography
                variant="h6"
                noWrap
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                P23D
            </Typography>
        </React.Fragment>
    );
}