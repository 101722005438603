// CropperComponent.js
import { PropsWithChildren, ReactElement, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Stack } from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';

export interface CropperProps extends PropsWithChildren {
    imageSrc: string,
    switchButton: ReactElement<typeof Button>,
    onCrop?: (img: string) => void,
}

const CropperComponent = ({ imageSrc, onCrop, switchButton, children }: CropperProps) => {
    const [cropper, setCropper] = useState<Cropper | null>(null);

    const cropImage = async () => {
        if (cropper) {
            const image = cropper.getCroppedCanvas().toDataURL('image/png');
            if (onCrop) onCrop(image);
        }
    };

    return (
        <Stack spacing={1}>
            <Cropper
                src={imageSrc}
                style={{ height: '100%', width: '100%', minHeight: 512 }}
                guides={false}
                crop={cropImage}
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
            />
            <Stack direction="row" spacing={1}>
                { switchButton }
                <Button
                    variant="contained"
                    onClick={cropImage}
                    startIcon={<CropIcon />}
                >
                    Crop
                </Button>
            </Stack>
            <Stack direction="row" spacing={1}>
                {children}
            </Stack>
        </Stack>
    );
};

export default CropperComponent;
