import { Dialog, DialogTitle, DialogContent, DialogContentText, LinearProgress, DialogActions, Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import WarningIcon from '@mui/icons-material/Warning';

export enum ConversionStage {
    IDLE,
    UPLOAD,
    STARTING_MODEL,
    GEN_VIEWS,
    CONSTRUCT_OBJ,
    LOAD_OBJ,
    DONE,
    NUM_STAGES,
};

export interface ConversionDialogProps {
    stage?: ConversionStage,
    error: string | null,
    onClose?: () => void
}

export default function ConversionDialog({stage = ConversionStage.IDLE, error, onClose}: ConversionDialogProps) {
    // close only if unlocked
    function onCloseUnlocked() {
        if (onClose && stage >= ConversionStage.DONE) {
            onClose();
        }
    }

    return (
        <Dialog open={stage !== ConversionStage.IDLE} onClose={onCloseUnlocked} maxWidth="xs" fullWidth>
            <DialogTitle>
                Creating 3D Model...
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This may take a while.
                </DialogContentText>
                <LinearProgress variant={stage === ConversionStage.DONE ? "determinate" : "indeterminate"} value={stage / (ConversionStage.NUM_STAGES - 1) * 100} sx={{ mt: 2 }}/>
                {
                    error &&
                    <DialogContentText color="error" sx={{ mt: 2 }}>
                        <Grid container direction="row" alignItems="center">
                            <WarningIcon fontSize="small" color="error" sx={{ mr: 2 }} />
                            { error }
                        </Grid>
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseUnlocked} disabled={stage !== ConversionStage.DONE}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}