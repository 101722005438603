import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { PropsWithChildren, Suspense, useEffect, useMemo, useRef, useState } from "react";
import * as Three from "three";
import { Button, Stack } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { ModelsApiResponse, StatusApiResponse } from "../types/api";

export interface ModelViewerProps extends PropsWithChildren {
    modelId: string | null;
    onLoaded?: () => void;
}

export interface ModelRotatorProps extends PropsWithChildren {
    delta: number;
}

// Component that rotates a model around its y axis
export function ModelRotator(props: ModelRotatorProps) {
    const primitiveRef = useRef<Three.Mesh>(null!);

    useFrame((state, delta) => {
        primitiveRef.current.rotation.y -= delta;
    });

    return (
            <mesh ref={primitiveRef}>
                {
                    props.children
                }
            </mesh>
    );
}

// simple cube with a magenta-black empty texture applied
// used as placeholder
function EmptyCube() {
    const colorMap = useLoader(Three.TextureLoader, `${process.env.PUBLIC_URL}/empty.png`);
    return (
        <mesh>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial map={colorMap} />
        </mesh>

    );
}

// try to load a texture file from the server and use as mesh material
function Texture({ modelId }: ModelViewerProps) {
    const texture = useLoader(Three.TextureLoader, `${process.env.REACT_APP_API_BASE}/texture/${modelId}`);
    return <meshStandardMaterial map={texture} />
}

// 3D model loaded from server
export function Model(props: ModelViewerProps) {
    const [reset, setReset] = useState<boolean>(false);

    // load obj file
    const obj = useLoader(
            OBJLoader,
            props.modelId != null ?
                `${process.env.REACT_APP_API_BASE}/model/${props.modelId}`
                :
                `${process.env.PUBLIC_URL}/404.obj`
            );

    if (obj && props.onLoaded) {
        // run callback when file was loaded
        props.onLoaded();
    }

    // get geometry and material from obj group
    // https://stackoverflow.com/a/68737225
    const [geometry, material] = useMemo(() => {
        let geo: unknown;
        let mat: unknown;
        obj.traverse((c) => {
            if (c.type === "Mesh") {
                const _c = c as Three.Mesh;
                geo = _c.geometry;
                mat = _c.material;
            }
        });
        return [geo, mat];
        }, [obj]);

    // console.log(material);

    // trigger a reload of the external texture when the model id is changed
    useEffect(() => {
        console.log("resetting error boundary because of model id change");
        setReset(r => !r);
    }, [props.modelId]);

    return (
        <mesh geometry={geometry as Three.BufferGeometry} scale={5}>
            <ErrorBoundary
                fallback={
                    // use the default texture from the loaded .obj
                    <meshPhongMaterial {...material as Three.MeshPhongMaterial} />
                }
                resetKeys={[reset]}
                >
                {/* try to load an external texture file */}
                <Texture modelId={props.modelId} />
            </ErrorBoundary>
        </mesh>
    );
}

// Three.js canvas displaying the model rotating
export function ModelViewer(props: ModelViewerProps) {
    const [objLoaded, setLoaded] = useState<boolean>(false);
    const [zip, setZip] = useState<boolean>(false);

    function handleLoaded() {
        setLoaded(true);
        if (props.onLoaded) props.onLoaded();
    }

    async function checkArchiveAvailability(modelId: string) {
        // get status of model
        let status: StatusApiResponse;
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_BASE}/status/${modelId}`);
            status = resp.data;
        } catch (err) {
            console.error(`Failed to get status for modelId ${modelId} while checking for archive`);
            setZip(false);
            return;
        }

        // only newer runs contain this extra data
        if (!("model" in status)) {
            setZip(false);
            return;
        }

        // get info about all models
        let models: ModelsApiResponse;
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_BASE}/models`);
            models = resp.data;
        } catch (err) {
            console.error("Failed to get /models while checking for archive ??");
            setZip(false);
            return;
        }

        if (!("models" in models && "default" in models)) {
            console.error("Invalid response from /models??");
            console.error(models);
            setZip(false);
            return;
        }

        // check if reported model exists in list
        let model = models["models"].find((m) => m.name === status["model"]);
        if (model === undefined) {
            // get default model
            console.log("failed to find model in list, using default");
            model = models["models"].find((m) => m.name === models["default"]);
        }

        if (model === undefined) {
            console.error("Default model not found in available models list??");
            setZip(false);
            return;
        }

        // check if the model provides archive
        setZip(model.provides.includes("archive"));
    }

    useEffect(() => {
        // block download button when model id gets changed
        setLoaded(false);
        // check if zip download is available for this model
        if (props.modelId) checkArchiveAvailability(props.modelId);
    }, [props.modelId]);

    return (
        <Stack spacing={1}>
            <Canvas style={{ height: 512 }}>
                <ambientLight intensity={1} />
                {
                    props.modelId ?
                        <Suspense fallback={
                            <ModelRotator delta={5}>
                                <EmptyCube />
                            </ModelRotator>
                        }>
                            <ModelRotator delta={5}>
                                <Model modelId={props.modelId} onLoaded={handleLoaded} />
                            </ModelRotator>
                        </Suspense>
                    :
                        <ModelRotator delta={5}>
                            <EmptyCube />
                        </ModelRotator>
                }
            </Canvas>
            <Stack direction="row" spacing={1}>
                <Button variant="contained"
                        href={
                            zip ?
                                `${process.env.REACT_APP_API_BASE}/archive/${props.modelId}/${props.modelId}.zip`
                                :
                                `${process.env.REACT_APP_API_BASE}/model/${props.modelId}/${props.modelId}.obj`
                        }
                        disabled={!props.modelId || !objLoaded}
                        download
                >
                    Download
                </Button>
                { props.children }
            </Stack>
        </Stack>
    );
}