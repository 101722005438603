import { PropsWithChildren, useState, useEffect, useRef, ReactElement, useCallback } from 'react';
import { Button, ButtonGroup, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { fabric } from 'fabric';

// stolen from cropper css
const CHECKERS_IMG = "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC)"

export interface CanvasProps extends PropsWithChildren {
    imageSrc: string;
    onDraw?: (img: string) => void;
    switchButton: ReactElement<typeof Button>;
}

const CanvasComponent = ({ imageSrc, onDraw, switchButton, children }: CanvasProps) => {
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [ initDone, setInitDone ] = useState<boolean>(false);
    const [ buttonLock, setButtonLock ] = useState<boolean>(false);
    const [ background, setBackground] = useState<fabric.Image | null>(null);

    const [ objectCount, setObjectCount ] = useState<number>(0);
    const [ redoQueue, setRedoQueue ] = useState<fabric.Object[]>([]);

    async function decodeImage(imgUrl: string) {
        const img = new Image();
        img.src = imgUrl;

        // wait for image to be loaded
        await img.decode();

        // extract width and height from image
        const fimg = new fabric.Image(img);
        if (img.height < 512) {
            console.log("refitting height");
            console.log(`w ${img.width} h ${img.height}`);
            fimg.scaleToHeight(512);
        }

        // get dimensions of canvas container
        const { width } = containerRef.current!.getBoundingClientRect();
        if (fimg.getScaledWidth() > width) {
            console.log("refitting width");
            fimg.scaleToWidth(width);
        }

        setBackground(fimg);
    }

    // function that gets triggered every time the canvas was updated
    const onCanvasUpdate = useCallback(() => {
        if (!canvasRef.current || !onDraw) return;

        // force a render of the current image
        canvasRef.current.renderAll();
        // access html canvas element and turn into data url
        onDraw(canvasRef.current.getElement().toDataURL("image/png"));
    }, [onDraw]);

    // function to initialize fabricjs canvas
    function initFabricCanvas(img: fabric.Image) {
        if (canvasRef.current) {
            canvasRef.current.dispose();
            console.log("disposed of canvas, because it already existed");
        }

        const c = new fabric.Canvas("imageCanvas", {
            backgroundImage: img,
            width: img.getScaledWidth(),
            height: img.getScaledHeight(),
            isDrawingMode: true,
        })

        const brush = new fabric.PencilBrush(c);
        brush.color = "#ffffff";
        brush.width = 30;

        c.freeDrawingBrush = brush;

        canvasRef.current = c;
    }

    // read in image info
    useEffect(() => {
        decodeImage(imageSrc);
    }, [imageSrc]);

    // initialize fabric canvas
    useEffect(() => {
        if (background) {
            initFabricCanvas(background);
            setInitDone(true);
        }
    }, [background]);

    // register callbacks after canvas was initialized
    useEffect(() => {
        if (initDone) {
            // register object:added callback to track number of objects
            canvasRef.current?.on("object:added", () => {
                setRedoQueue([]);
                setObjectCount(cnt => cnt + 1);
                onCanvasUpdate();
            });
        }
    }, [initDone, onCanvasUpdate]);

    interface CanvasButtonProps extends PropsWithChildren{
        onClick: (canvas: fabric.Canvas) => void,
        disabled?: boolean,
        icon?: React.ReactNode
    };

    function CanvasButton({ onClick, disabled, icon, children }: CanvasButtonProps) {
        return (
            <Button
                variant="contained"
                onClick={() => {
                    if (canvasRef.current) {
                        onClick(canvasRef.current);
                    }
                }}
                disabled={!initDone || buttonLock || disabled}
                startIcon={icon}
            >
                {
                    children
                }
            </Button>
        );
    }

    // clear button handler
    function handleClear(c: fabric.Canvas) {
        setButtonLock(true);
        c.clear();

        // set background image again
        if (background) {
            c.setBackgroundImage(background, () => {
                // clear undo history
                setObjectCount(0);
                setRedoQueue([]);

                onCanvasUpdate();
                setButtonLock(false);
            });
        }
    }

    // undo button handler
    function handleUndo(c: fabric.Canvas) {
        // get current objects and remove newest one
        const objs = c.getObjects();
        if (objs.length > 0) {
            const rem = objs[objs.length - 1];
            c.remove(rem);

            setObjectCount(objs.length - 1);
            setRedoQueue(r => [
                ...r,
                rem
            ]);

            onCanvasUpdate();
        }
    }

    // redo button handler
    function handleRedo(c: fabric.Canvas) {
        // get last object in redo queue and apply it to the canvas
        if (redoQueue.length === 0) return;
        const obj = redoQueue[redoQueue.length - 1];
        c.add(obj);

        setObjectCount(cnt => cnt + 1);
        setRedoQueue(redoQueue.filter((v, i, a) => i !== a.length - 1));

        onCanvasUpdate();
    }

    return (
        <Grid container columnSpacing={1} rowSpacing={1} sx={{ pl: 0 }}>
            {/* Drawing Canvas */}
            <Grid xs={12}>
                {/* Wrapped into a stack container that will center the canvas component within itself */}
                <Stack
                    spacing={0}
                    alignItems={"center"}
                    justifyContent={"center"}
                    minHeight={512}
                    width={"100%"}
                    height={"100%"}
                    ref={containerRef}
                    sx={{ backgroundImage: CHECKERS_IMG, backgroundColor: "gray", backgroundBlendMode: "multiply" }}
                    >
                        <canvas
                            id="imageCanvas"
                            />
                </Stack>
            </Grid>
            {/* Tool switch button + Row of buttons for the drawing tool */}
            {/* This row may be split into two rows, depending on screen size */}
            <Grid xs={12} sm={"auto"}>
                { switchButton }
            </Grid>
            <Grid xs={12} sm={"auto"}>
                <ButtonGroup variant="contained">
                    <CanvasButton onClick={handleClear} icon={<DeleteForeverIcon />}>
                        Clear
                    </CanvasButton>
                    <CanvasButton onClick={handleUndo} disabled={objectCount === 0} icon={<UndoIcon />}>
                        Undo
                    </CanvasButton>
                    <CanvasButton onClick={handleRedo} disabled={redoQueue.length === 0} icon={<RedoIcon />}>
                        Redo
                    </CanvasButton>
                </ButtonGroup>
            </Grid>
            {/* Row of buttons passed down from App */}
            <Grid xs={12}>
                <Stack direction="row" spacing={1}>
                    {children}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CanvasComponent;
